<template>
    <div class="container page">
      <van-nav-bar :title="$t('taskcp.promotion')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
      
      <div class="items">
        <div class="item van-hairline--bottom">
          <div class="left">{{$t("taskcp.nodata")}}</div>
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isLoading: false,
        list:[],
      };
    },
    methods: {
      back(){
        return window.history.back();
      }
  
    },
    created() {
        
    }
  };
  </script>
  
  <style lang='less' scoped>
  @import "../../assets/css/base.css";
  </style>
  