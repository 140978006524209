<template>

  <div class="page">
    <!-- <img class="bg-img" v-if="$store.getters.getBaseInfo.login_bg" :src="$store.getters.getBaseInfo.login_bg" /> -->
    <div class="bg-wrapper">
      <div class="register">

        <!-- <van-nav-bar :title="$t('auth.no_account')" class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()"/>
          </template>
        </van-nav-bar> -->
        <div class="wrapper">
        
          <!-- <div class="title">{{$t("auth.register")}}</div> -->
            <!-- logo -->
            <div class="img_box">
            <!-- <img class="logo" src="../../assets/Logo_NK.svg" alt=""> -->
            ĐIỆN MÁY XANH
          </div>
          <div class="loginForm">
            <van-field v-model="username"  clearable input-align="left" class="input"  :placeholder="$t('auth.username_place')" />
          
            <van-field v-model="code"  clearable input-align="left" class="input"  :placeholder="$t('auth.invite_code_place')" />
            <van-field
                v-model="password"
                :type="passwordType"
                input-align="left"
                class="input"
                :placeholder="$t('auth.pwd_place')">
              <template slot="right-icon">
                <van-icon :name=" passwordType === 'password' ? 'closed-eye':'eye-o'" @click="switchPasswordType"/>
              </template>
            </van-field>
            <van-field
                v-model="confirmPassword"
                :type="passwordTypeConfirm"
                input-align="left"
                class="input"
                :placeholder="$t('确认密码')">
              <template slot="right-icon">
                <van-icon :name=" passwordTypeConfirm === 'password' ? 'closed-eye':'eye-o'" @click="switchPasswordTypeTwo"/>
              </template>
            </van-field>
            <!-- <div class="agreement">
              <van-checkbox v-model="checked" />
              <span class="agreement-text">{{$t("auth.agreement_place")}}</span>
            </div> -->
            <!-- <van-button class="login-btn"  :disabled="isDisabled" type="primary" size="normal" @click="doRegister()">{{$t("auth.register")}}</van-button> -->
           <!-- 登录按钮 -->
           <div class="login_btn">
              <div class="func_btn" @click="doRegister()" :disabled="isDisabled">{{ $t("auth.register") }}
                <img class="login_enter" src="../../assets/login_enter.png" alt="">
              </div>
            </div>

            <!-- 忘记密码 -->
            <div class="forget_pass_box">
              <div class="left_txt">{{ $t('已经有帐户？') }}</div>
              👉
              <div class="right_txt" @click="$router.go(-1)">{{ $t('现在登录') }}</div>
            </div>

            <div class="img_box_login">
              <img class="login_01" src="../../assets/login_01.png" alt="">
            </div>

          
          </div>
        </div>
        <div class="auth_form_copyright">
          <div class="txt_box">
            Copyright © 1999 - 2023 Công ty Cổ phần Thương mại Nguyễn Kim. <br />
            Giấy chứng nhận đăng ký kinh doanh số 0302286281, cấp ngày 22/06/2006 bởi Sở Kế hoạch và Đầu tư TP. Hồ Chí
            Minh. Địa chỉ đăng ký trụ sở chính: 63-65-67 Trần Hưng Đạo, P. Cầu Ông Lãnh, TP. Hồ Chí Minh
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked: true,
      username: '',
      code: localStorage.getItem("incod") || '',
      lang: '',
      password: this.inputValue,
      confirmPassword:'',
      passwordType: 'password',
      isDisabled:false,
      passwordTypeConfirm:'password',
    };
  },
  mounted(){
    this.lang = localStorage.getItem("viodeLang") || this.$store.state.langDefault;
  },
  methods: {
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    switchPasswordTypeTwo() {
      this.passwordTypeConfirm = this.passwordTypeConfirm === 'password' ? 'text' : 'password'
    },
    back(){
      return window.history.back();
    },
    doRegister() {
      if(this.username === "" || this.username === null || this.username === undefined){
        this.$toast.fail(this.$t("auth.username_place"));
        return false;
      }
      if(this.password === "" || this.password === null || this.password === undefined){
        this.$toast.fail(this.$t("auth.pwd_place"));
        return false;
      }
      if(this.code === "" || this.code === null || this.code === undefined){
        this.$toast.fail(this.$t("auth.invite_code_place"));
        return false;
      }
      if(this.confirmPassword === "" || this.confirmPassword === null || this.confirmPassword === undefined){
        this.$toast.fail(this.$t("重复密码不能为空"));
        return false;
      }
      if(this.confirmPassword!=this.password){
        this.$toast.fail(this.$t("两次密码不一致，清重新输入"));
        return false;
      }
      
      this.isDisabled = true
      this.$http({
        method: 'post',
        data:{
          username:this.username,
          password:this.password,
          code:this.code,
          lang: this.lang
        },
        url: 'member_register'
      }).then(res=>{
        if (res.code === 200) {
            this.$toast.success(res.msg);
            localStorage.setItem("tokensso", res.data.tokensso);
            localStorage.setItem('token',res.data.id)
            this.$router.push("Mine")
          }else {
            this.$toast.fail(res.msg);
        }
          this.isDisabled = false
      })
    }
  },
  created() {
    if(localStorage.getItem('token')){
      return this.$router.push('/')
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.register{
  height: 100%;
  background: linear-gradient(-180deg, #4a90e2, #4a90e2);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.img_box {
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size:50px;
  .logo{
    width: 72%;
  }

}
.login_btn {
  margin-top: 40px;

  .func_btn {
    align-items: center;
    background: #fefb0b;
    border-radius: 50px;
    color: var(--text);
    font-size: 32px;
    font-weight: 600;
    height: 90px;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    justify-content: center;

    .login_enter {
      width: 40px;
      height: 30px;
      padding-left: 10px;
    }
  }

}

.img_box_login {
  margin-top: 50px;

  .login_01 {
    width: 100%;

  }
}
.forget_pass_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .left_txt {
    color: white;
    font-size: 28px;
    margin-right: 10px;
  }

  .right_txt {
    color: #f4da20;
    font-size: 28px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 5px;
  }
}

.auth_form_copyright {
  flex: 1;
  position: relative;
  margin: 0 20px;
  margin-bottom: 20px;

  .txt_box {
    position: absolute;
    bottom: 0;
    color: white;
    font-size: 23px;
    opacity: 0.6;
    text-align: center;

  }

}


.bg-container .bg-wrapper .register .nav-bar{
  background: 0 0
}
.register .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.logo-container{
  margin: 0 auto;
  width: 45%;
}
.logo-container .logo-wrapper{
  position: relative;
  padding-bottom: 62.5%;
}
.logo-container .logo-wrapper .logo-img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  opacity: 0.8;
}
.register .wrapper .title{
  line-height: 100px;
  text-align: center;
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 5px;
}
.register .wrapper .loginForm{
  padding: 20px 60px;
}
.register .wrapper .loginForm .input{
  padding: 22px;
  margin-top: 40px;
  border-radius: 50px;
  text-align: left !important;
  font-size: 28px;
  color: #444756;
}
::v-deep .van-field__right-icon .van-icon {
  font-size: 50px;
}
::v-deep .van-icon {
  font-size: 50px;
}
.register .wrapper .loginForm .reset-text{
  margin: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.register .wrapper .loginForm .reset-text span{
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 15px;
}
.register .wrapper .loginForm .register-text{
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register .wrapper .loginForm .register-text span{
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
}
.register .wrapper .loginForm .login-btn{
  margin-top: 30px;
  width: 100%;
  height: 100px;
  border-radius: 50px;
  color: #fff;
  background-color: #4a90e2;
  font-size: 30px;
  font-weight: bolder;
  border: none;
}
.register .wrapper .loginForm .agreement{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register .wrapper .loginForm .agreement .agreement-text{
  margin-left: 10px;
  font-size: 25px;
  color: #999;
  font-weight: 500;
  line-height: 30px;
}
::v-deep .agreement .van-icon {
  font-size: 30px;
}
::v-deep .agreement .van-checkbox__icon {
  font-size: 38px;
}
::v-deep .agreement .van-checkbox__icon--checked .van-icon {
  color: #fff;
  border-color: rgb(126, 86, 120);
  background-color: rgb(126, 86, 120);
}
.page{
  height:100%;
}
</style>
