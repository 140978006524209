<template>
  <div class="mine page">

    <AppTop/>
    <div class="wrapper">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')"
        :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
        <div class="user_box" @click="$router.push('Infomation')">
          <div class="left">
            <img class="header_img" src="../../assets/header.jpg" alt="">
          </div>
          <div class="right">
            <div class="title">Tài khoản người dùng</div>
            <div class="username">{{ this.userInfo.username }}</div>
            <div class="yue">Số dư:{{ this.userInfo.money }} đ</div>
          </div>
        </div>

        <van-grid :border=false :column-num="2">
          <van-grid-item>

            <div class="grid-item" @click="doPay()">
              <img class="grid-item-icon" src="@/assets/taskcp/mine/ico2.png" alt="">
              <span>{{ $t('recharge.recharge') }}</span>
            </div>

          </van-grid-item>
          <van-grid-item>

            <div class="grid-item" @click="doWithdrawal()">
              <img class="grid-item-icon" src="@/assets/taskcp/mine/ico3.png" alt="">
              <span>{{ $t('recharge.withdraw') }}</span>
            </div>

          </van-grid-item>
        </van-grid>
        <van-cell-group>


          <van-cell :title="$t('my.task_record')" is-link center @click="$router.push({ path: '/GameRecord' });">
            <template #icon>
              <img class="grid-item-icon" src="@/assets/taskcp/mine/ico4.png" alt="">
            </template>
          </van-cell>
          <!-- <van-cell :title="$t('my.account_detail')" is-link center  @click="$router.push({ path: '/Moneylog' });">
    <template #icon>
              <img class="grid-item-icon" src="@/assets/taskcp/mine/ico5.png" alt="">
    </template>
  </van-cell> -->
          <van-cell :title="$t('my.my_statement')" is-link center @click="$router.push({ path: '/Personalreport' });">
            <template #icon>
              <img class="grid-item-icon" src="@/assets/taskcp/mine/ico6.png" alt="">
            </template>
          </van-cell>
          <van-cell :title="$t('withdraw.with_record')" is-link center
            @click="$router.push({ path: '/WithdrawRecord' });">
            <template #icon>
              <img class="grid-item-icon" src="@/assets/taskcp/mine/ico7.png" alt="">
            </template>
          </van-cell>
          <van-cell :title="$t('recharge.with_record')" is-link center
            @click="$router.push({ path: '/RechargeRecord' });">
            <template #icon>
              <img class="grid-item-icon" src="@/assets/taskcp/mine/ico8.png" alt="">
            </template>
          </van-cell>
          <van-cell :title="$t('setting.login_pwd')" is-link center @click="$router.push({ path: '/share' });">
            <template #icon>
              <img class="grid-item-icon" src="@/assets/taskcp/mine/yaoq.png" alt="">
            </template>
          </van-cell>

        </van-cell-group>


        <van-cell-group style="margin-top: 10px;">


          <van-cell :title="$t('setting.base_setting')" is-link center @click="doLogin">
            <template #icon>
              <img class="grid-item-icon" src="@/assets/taskcp/mine/ico10.png" alt="">
            </template>
          </van-cell>
          <!-- <van-cell :title="$t('my.account_detail')" is-link center  @click="$router.push({ path: '/Moneylog' });">
    <template #icon>
              <img class="grid-item-icon" src="@/assets/taskcp/mine/ico5.png" alt="">
    </template>
  </van-cell> -->
          <!-- <van-cell :title="$t('taskcp.vip')" is-link center  @click="$router.push({ path: '/vip' });">
    <template #icon>
              <img class="grid-item-icon" src="@/assets/taskcp/mine/ico11.png" alt="">
    </template>
  </van-cell> -->
          <van-cell :title="$t('setting.bank_info')" is-link center @click="$router.push({ path: '/Setbank' });">
            <template #icon>
              <img class="grid-item-icon" src="@/assets/taskcp/mine/ico12.png" alt="">
            </template>
          </van-cell>
          <van-cell :title="$t('setting.login_pwd')" is-link center @click="$router.push({ path: '/Setting' });">
            <template #icon>
              <img class="grid-item-icon" src="@/assets/taskcp/mine/ico13.png" alt="">
            </template>
          </van-cell>
          <van-cell :title="$t('my.sys_notice')" is-link center @click="$router.push({ path: '/Notice' });">
            <template #icon>
              <img class="grid-item-icon" src="@/assets/taskcp/mine/ico14.png" alt="">
            </template>
          </van-cell>

        </van-cell-group>
        <!-- <van-cell-group style="margin-top: 10px;">

          <van-cell :title="$t('my.online_service')" is-link center @click="toService()">
            <template #icon>
              <img class="grid-item-icon" src="@/assets/taskcp/mine/ico15.png" alt="">
            </template>
          </van-cell>
        </van-cell-group> -->

        <div class="outlog" @click="loginout()" style="margin-top: 10px;" v-if="isLogin">
          {{ $t('setting.logout') }}
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import header from '../../assets/header.jpg';
import AppTop from '../AppTop/AppTop.vue'
export default {
  data() {
    return {
      userInfo: {
      },
      menu_top: 40,
      isLoading: false,
      isLogin: false,
      header: header
    };
  },
  methods: {
    refresh() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          // this.$toast(this.$t("reservation.refresh"));
        } else {
          this.$router.push({ path: '/Login' })
        }
      }, 500);
    },
    exit() {
      this.$toast(this.$t("my.finish_task"));
    },
    showSetting() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Setting' })
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    toNotice() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Notice' })
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          this.getUserInfo();
          // this.$toast(this.$t("reservation.refresh"));
        } else {
          this.$router.push({ path: '/Login' })
        }
      }, 500);
    },
    loginout() {
      localStorage.clear()
      this.$router.push({ path: '/Login' });
    },
    doLogin() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Infomation' });
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    doPay() {
      this.$router.push({
        name: 'Recharge',
        params: {
          'balance': this.userInfo.money
        }
      })
    },
    doWithdrawal() {
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res => {
        if (res.data.is_bank) {
          this.$router.push("withdraw");
        } else {
          this.$router.push("Setbank");
          this.$toast.fail(this.$t("setting.set_bank"));
        }
      })
    },
    toService() {
      if (this.$store.getters.getBaseInfo.iskefu == 1) {
        this.$router.push("ServiceOnline");
      } else {
        this.$toast.fail(this.$t("setting.forbid"));
      }
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.menu_top = 15;
          if (this.userInfo.status !== 1) {
            this.$toast(this.$t("video.account_out"));
            localStorage.clear()
            this.$router.push({ path: '/Login' })
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
  },
  created() {
    this.isLogin = localStorage.getItem('token') ? true : false
    if (localStorage.getItem('token')) {
      this.getUserInfo();
    } else {
      this.userInfo.username = this.$t("setting.log_reg");
      this.userInfo.ip = this.$t("setting.more_service");
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  },
  components: {
    AppTop
  }
};
</script>

<style scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
}

.user_box {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 50px;
  background: #4a90e2;
  margin: 0 25px;
  margin-top: 130px;
  border-radius: 20px;

  .left {
    /* width: 260px;
    height: 260px;
    background-image: url('../../assets/header_bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center; */

    .header_img {
      width: 130px;
      height: 130px;
      border-radius: 100%;
    }
  }

  .right {
    padding-left: 20px;

    .title {
      font-size: 28px;
      color: white;

    }

    .username {
      font-size: 32px;
      font-weight: 600;
      color: white;
    }

    .yue {
      color: white;
      font-size: 32px;
      font-weight: 500;
      margin-top: 10px;
    }
  }
}

.mine {
  position: relative;
  bottom: 10px;
  background: #f2f2f5;
}

.mine .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 180px;
}

.nav-bar {
  background: linear-gradient(90deg, #4a90e2, #4a90e2);
}

.mine .header {
  background: linear-gradient(90deg, #4a90e2, #4a90e2);
  /* padding: 20px 0; */
}

::v-deep .van-nav-bar__content {
  height: 100px;
}

::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0px;
}

.mine .header .van-nav-bar .van-icon {
  font-size: 45px;
}

.mine .header .user-wrapper {
  display: flex;
  align-items: center;
  padding: 30px;
  background: #ea4837;
  margin-top: 100px;
}

.mine .user_img {
  height: 130px;
  width: 130px;
}

::v-deep .van-loading__spinner {
  height: 50px;
  width: 50px;
}

::v-deep .van-image__error-icon {
  font-size: 70px;
}

.mine .header .user-wrapper .login-content {
  flex: 1;
  margin-left: 30px;
}

.mine .header .user-wrapper .login-content .login-btn {
  display: inline-block;
  font-size: 40px;
  line-height: 0px;
  color: #fff;
  display: flex;
  align-items: center;
}

.mine .header .user-wrapper .login-content .login-label {
  /* margin-top: -13px; */
  width: 60%;
  font-size: 28px;
  color: hsla(0, 0%, 100%, .6);

}

.mine .page-bg {
  height: 500px;
  background: linear-gradient(90deg, #4a90e2, #4a90e2);
  z-index: 0;
  background-size: cover;

}

.mine .content {
  position: relative;
  padding: 10px 30px 30px;
  min-height: 500px;
  background-color: #f2f2f5;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #ffffff;
  font-size: 35px;
}

.mine .wrapper .content .finance {
  position: absolute;
  display: flex;
  align-items: center;
  top: -55px;
  left: 30px;
  right: 30px;
  height: 120px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .finance .line {
  width: 3px;
  height: 40px;
  background-color: #ccc;
}

.mine .wrapper .content .finance .finance-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mine .wrapper .content .finance .finance-item .text {
  margin-left: 30px;
  font-size: 30px;
  color: #000;
  font-weight: 500;
}

.mine .wrapper .content .finance .finance-item .icon {
  font-size: 50px;
}

.mine .wrapper .content .menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .menu .menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 130px;
}

.mine .wrapper .content .menu .menu-item .menu-item-label {
  font-size: 30px;
  color: #868686;
  font-weight: 500;
}

.mine .wrapper .content .menu .menu-item .menu-item-icon {
  margin: 25px;
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
}

.mine .wrapper .content .wallet {
  margin-top: 80px;
  padding: 0 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .wallet .part-1 {
  display: flex;
  align-items: center;
  height: 100px;
}

.mine .wrapper .content .wallet .font-primary-color {
  color: #000;
}

.font-gray {
  color: #868686;
}

.mine .wrapper .content .wallet .part-2 {
  display: flex;
  align-items: center;
  height: 150px;
}

.mine .wrapper .content .wallet .part-2 .balance {
  flex: 1;
  font-size: 60px;
  color: #4a90e2;
  font-weight: 700;
}

.mine .wrapper .content .wallet .van-hairline--bottom::after {
  border-bottom-width: 3px;
}

.mine .wrapper .content .wallet .part-2 .refresh-btn {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 30px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #4a90e2;
}

.login-vip {
  width: 142px;
  height: 45px;
  margin-left: 0px;
  margin-bottom: -12px;
}

.userInfo_money {
  text-align: center;
  color: #fff;
  font-size: 32px;
  background: #fc4e3c;
  padding: 10px 0;
}

.userInfo_money p {
  margin: 10px 0;
}

.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
}

.grid-item-icon {
  width: 60px;
  margin-right: 10px;
}

.paihan {
  position: relative;
  margin-right: -30px;
  margin-top: -80px;
  width: 200px;
  height: 60px;
}

.paihan_ico {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.paihan_bg {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.paihan .txt {
  margin-left: 80px;
  position: relative;
  z-index: 3;
  line-height: 60px;
  color: #fff;
  font-size: 26px;
  padding-right: 10px;
  /* justify-content: center; */
  align-items: center;
}

.replay {
  color: #fff;
  font-size: 40px;
  margin-top: 10px;
  text-align: center;
  display: flex;
  margin-left: 60%;
}

.userInfo_money_txt {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .van-cell {
  padding: 30px 22px;
  font-size: 30px;
  line-height: 30px;
}

::v-deep .van-cell__left-icon,
.van-cell__right-icon {
  font-size: 40px;
}

.outlog {
  padding: 36px;
  background: #fff;
  font-size: 32px;
  text-align: center;
}</style>
