<template>
  <div class="page">
    <!-- <img class="bg-img" v-if="$store.getters.getBaseInfo.login_bg" :src="$store.getters.getBaseInfo.login_bg" /> -->
    <div>
      <div class="login">
        <!-- <van-nav-bar :title="$t('my.task_record')" class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()" />
          </template>
        </van-nav-bar> -->
        <div class="wrapper">
          <!-- <div class="logo-container">
            <div class="logo-wrapper">
              <img class="logo-img" :src="this.$store.getters.getBaseInfo.ico !== undefined
                ? this.$store.getters.getBaseInfo.ico
                : '/img/null.png'
                " />
            </div>
          </div> -->
          <!-- logo -->
          <div class="img_box">
            <!-- <img class="logo" src="../../assets/Logo_NK.svg" alt=""> -->
            ĐIỆN MÁY XANH
          </div>
          <!-- <div class="title">{{ this.$store.getters.getBaseInfo.name}}</div> -->
          <div class="loginForm">
            <van-field v-model="username" clearable input-align="left" class="input"
              :placeholder="$t('auth.username_place')" />
            <van-field v-model="password" :type="passwordType" input-align="left" class="input"
              :placeholder="$t('auth.pwd_place')">
              <template slot="right-icon">
                <van-icon :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'" @click="switchPasswordType" />
              </template>
            </van-field>
            <!-- <div class="reset-text" @click="noPsw">
              <span>{{ $t("auth.forgetpwd") }}?</span>
            </div> -->
            <!-- <div @click="toRegister()" class="register-text">
              <span>{{$t("auth.no_account")}}</span>
            </div> -->
            <!-- 登录按钮 -->
            <div class="login_btn">
              <div class="func_btn" @click="doLogin()" :disabled="isDisabled">{{ $t("auth.login") }}
                <img class="login_enter" src="../../assets/login_enter.png" alt="">
              </div>
            </div>

            <!-- 忘记密码 -->
            <div class="forget_pass_box">
              <div class="left_txt">{{ $t('没有账号？') }}</div>
              👉
              <div class="right_txt" @click="toRegister()">{{ $t('立即注册') }}</div>
            </div>

            <div class="img_box_login">
              <img class="login_01" src="../../assets/login_01.png" alt="">
            </div>

          </div>
        </div>
        <div class="auth_form_copyright">
          <div class="txt_box">
            Copyright © 1999 - 2023 Công ty Cổ phần Thương mại Nguyễn Kim. <br />
            Giấy chứng nhận đăng ký kinh doanh số 0302286281, cấp ngày 22/06/2006 bởi Sở Kế hoạch và Đầu tư TP. Hồ Chí
            Minh. Địa chỉ đăng ký trụ sở chính: 63-65-67 Trần Hưng Đạo, P. Cầu Ông Lãnh, TP. Hồ Chí Minh
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "inputValue",
    event: "input",
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      username: "",
      lang: '',
      password: this.inputValue,
      passwordType: "password",
      isDisabled: false
    };
  },
  mounted() {
    // localStorage.setItem("lang", 'ms_my');
    this.lang = localStorage.getItem("viodeLang") || this.$store.state.langDefault;
  },
  methods: {
    switchPasswordType() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    back() {
      this.$router.push('/')
      // return window.history.back();
    },
    noPsw() {

      this.$toast(this.$t("auth.customerService"));
    },
    toRegister() {
      this.$router.push("Register");
    },
    doLogin() {
      if (
        this.username === "" ||
        this.username === null ||
        this.username === undefined
      ) {
        this.$toast(this.$t("auth.username_place"));
        return false;
      }
      if (
        this.password === "" ||
        this.password === null ||
        this.password === undefined
      ) {
        this.$toast(this.$t("auth.pwd_place"));
        return false;
      }
      this.isDisabled = true
      this.$http({
        url: "member_login",
        method: "post",
        data: {
          username: this.username,
          password: this.password,
          lang: this.lang
        },
      }).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.msg);
          localStorage.setItem("tokensso", res.data.tokensso);
          localStorage.setItem("token", res.data.id);
          this.$router.push("Home");
        } else {
          this.$toast(res.msg);
        }
        this.isDisabled = false
      });
    },
  },
  created() {
    this.$http({
      method: 'get',
      url: 'base_info'
    }).then(res => {
      if (res.code == 200) {

        if (!localStorage.getItem('token') && res.data.video_login == 1) {
          this.$router.push({ path: '/Login' })
        }
        this.$store.commit('setBaseInfoValue', res.data);
      }
    })
    if (localStorage.getItem("token")) {
      return this.$router.push('/')
    }
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.page {
  background: linear-gradient(-180deg, #4a90e2, #4a90e2);
}

.login {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

}

.login_btn {
  margin-top: 40px;

  .func_btn {
    align-items: center;
    background: #fefb0b;
    border-radius: 50px;
    color: var(--text);
    font-size: 32px;
    font-weight: 600;
    height: 90px;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    justify-content: center;

    .login_enter {
      width: 40px;
      height: 30px;
      padding-left: 10px;
    }
  }

}

.img_box_login {
  margin-top: 50px;

  .login_01 {
    width: 100%;

  }
}
.forget_pass_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .left_txt {
    color: white;
    font-size: 28px;
    margin-right: 10px;
  }

  .right_txt {
    color: #f4da20;
    font-size: 28px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 5px;
  }
}

.auth_form_copyright {
  flex: 1;
  position: relative;
  margin: 0 20px;
  margin-bottom: 20px;

  .txt_box {
    position: absolute;
    bottom: 0;
    color: white;
    font-size: 23px;
    opacity: 0.6;
    text-align: center;

  }

}



.login .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.img_box {
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size:50px;
  .logo{
    width: 72%;
  }
}

.logo-container {
  margin: 0 auto;
  width: 45%;
}

.logo-container .logo-wrapper {
  position: relative;
  padding-bottom: 62.5%;
}

.logo-container .logo-wrapper .logo-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  opacity: 0.8;
}

.login .wrapper .title {
  line-height: 100px;
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 5px;
}

.login .wrapper .loginForm {
  padding: 20px 60px;
}

.login .wrapper .loginForm .input {
  padding: 22px;
  margin-top: 40px;
  border-radius: 50px;
  text-align: left !important;
  font-size: 28px;
  color: #444756;
}

::v-deep .van-field__right-icon .van-icon {
  font-size: 50px;
}

::v-deep .van-icon {
  font-size: 50px;
}

.login .wrapper .loginForm .reset-text {
  margin: 30px 15px;
  text-align: left;
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
}

.login .wrapper .loginForm .reset-text span {
  color: #999;
  font-size: 25px;
  font-weight: 500;
  line-height: 15px;
}

.login .wrapper .loginForm .register-text {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login .wrapper .loginForm .register-text span {
  color: #999;
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
}


.login .wrapper .loginForm .login-btn {
  margin-top: 30px;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  color: #fff;
  background: #4a90e2;
  font-size: 30px;
  font-weight: bolder;
  border: none;
}

.page {
  height: 100%;
}</style>
