<template>
    <div class="container page">
      <van-nav-bar :title="$t('taskcp.bookRoom')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
      <div class="main">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')" :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
          <van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
          <div v-else class="item_list" style="display: flex;justify-content: center; align-items: center; background: #fff;border-bottom: 1px solid #eee;padding: 10px 15px;" v-for="(v,key) in list" :key="key">
            <img src="@/assets/logo.png" style="width: 40px;height: 40px;margin-right: 10px;" alt="">
            <div class="" style="width: 100%;">
                <div class="name" style="display: flex;">

                    LOREM IPSUM
                  
                    <div class="time" style="margin-left: auto;">
                    2023-10-02 22:44:04
                  </div>

                </div>
                <div class="num" style="display: flex;margin:10px 0">

                    202310020682 lịch sử
                  
                    <div style="margin-left: auto;">></div>
                </div>
                <div class="number">3,6,3</div>
            </div>
          </div> 
        </van-pull-refresh>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isLoading: false,
        list:[],
      };
    },
    methods: {
      back(){
        return window.history.back();
      },
      onRefresh() {
        setTimeout(() => {
          this.$toast(this.$t("reservation.refresh"));
          this.isLoading = false;
        }, 500);
      },
      getUserGameList(){
        this.$http({
          method: 'get',
          url: 'user_get_game_list'
        }).then(res => {
          if (res.code === 200) {
            console.log(res.data)
            this.list = res.data;
          }else if(res.code ===401){
            this.$toast(res.msg);
          }
        })
      }
  
    },
    created() {
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.getUserGameList();
      }
    }
  };
  </script>
  
  <style lang='less' scoped>
  @import "../../assets/css/base.css";
  </style>
  