<template>
    <div class="container page">
      <van-nav-bar :title="$t('taskcp.vip')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
      <div class="main">
        <div class="taskcp_tab">
          <van-tabs v-model="taskcp_tab_active" animated sticky line-width="100px" :swipeable="true">
            <van-tab :title="$t('taskcp.viptab1')" ></van-tab>
            <van-tab :title="$t('taskcp.viptab2')"  ></van-tab>
          </van-tabs>
          <div class="taskcp_table">
            <template v-if="taskcp_tab_active == 0">
            
              <div class="taskcp_table_list" >
                {{ $t('taskcp.viptab1_table1') }}
              </div>
              <div class="taskcp_table_list" >
                {{ $t('taskcp.viptab1_table2') }}
              </div>
              <div class="taskcp_table_list" >
                {{ $t('taskcp.viptab1_table3') }}
              </div>
              <div class="taskcp_table_list" >
                {{ $t('taskcp.viptab1_table4') }}
              </div>
            </template>
            <template v-else>
            
              <div class="taskcp_table_list" >
                {{ $t('taskcp.viptab2_table1') }}
              </div>
              <div class="taskcp_table_list" >
                {{ $t('taskcp.viptab2_table2') }}
              </div>
              <div class="taskcp_table_list" >
                {{ $t('taskcp.viptab2_table3') }}
              </div>
              <div class="taskcp_table_list" >
                {{ $t('taskcp.viptab2_table4') }}
              </div>
            </template>
          </div>
        </div>
        <van-pull-refresh v-if="taskcp_tab_active == 0" v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')" :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
          <van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
          <div v-else class="item_list" v-for="(v,key) in list" :key="key">

            1
          </div> 
        </van-pull-refresh>
        <div v-else>

          <div class="taskcp_table" style="background: #fff;">
              <div class="taskcp_table_list" >
                1
                </div>
              <div class="taskcp_table_list" style="display: flex;align-items: center;" >
              
                <img src="https://api.amzgoshop.xyz//member/20230508/55535e57e6d2a2dafdac2f38bcd1d40d.png" style="width: 80px;" alt="">
                </div>
              <div class="taskcp_table_list" >
                Vip1
                </div>
              <div class="taskcp_table_list" >
               0
                </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isLoading: false,
        list:[],
        taskcp_tab_active:0
      };
    },
    methods: {
      back(){
        return window.history.back();
      },
      onRefresh() {
        setTimeout(() => {
          this.$toast(this.$t("reservation.refresh"));
          this.isLoading = false;
        }, 500);
      },
      getUserGameList(){
        this.$http({
          method: 'get',
          url: 'user_get_game_list'
        }).then(res => {
          if (res.code === 200) {
            console.log(res.data)
            this.list = res.data;
          }else if(res.code ===401){
            this.$toast(res.msg);
          }
        })
      }
  
    },
    created() {
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.getUserGameList();
      }
    }
  };
  </script>
  
  <style lang='less' scoped>
  @import "../../assets/css/base.css";

::v-deep .van-tab {
	font-size: 30px;
	line-height: 35px;
    text-align: center;
	font-weight: bold;
}
::v-deep .van-tab__text--ellipsis{

  -webkit-line-clamp: 2;
}
::v-deep .van-tabs__line {
	background-color: #4a90e2;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}

  </style>
  