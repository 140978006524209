<template>
    <div class="apptop-container" >
  
        <div class="header_main">
          <img @click="leftShow = true" src="@/assets/left_ico.png" class="left_ico" alt="" srcset="">
          <!-- <img class="Logo_NK" src="../../assets/Logo_NK.svg" /> -->
          ĐIỆN MÁY XANH
          <img @click="toKefu" src="../../assets/customer.png" class="right_ico" alt="" srcset="">
          <!-- <van-icon name="chat-o" size="20" color="#fff" :badge="notice_total" @click="toNotice" /> -->
        </div>
  
  
      <van-popup v-model="leftShow" position="left" :style="{ width: '50%', height: '100%',  background: 'linear-gradient(-180deg, #4a90e2, #4a90e2)' }">
  
        <div style="margin-top: 20px;">
  
          <img @click="leftShow = false" src="@/assets/left_ico.png" class="left_ico" alt="" srcset="">
  
        </div>
        <div class="menu-item" @click="$router.push({ path: '/Home' });">
          <!-- <van-image class="menu-item-icon" src="img/mine/user.svg">
            <template v-slot:loading>
              <van-loading type="spinner" />
            </template>
          </van-image> -->
          <span class="menu-item-label">{{ $t("主页") }}</span>
        </div>
        <div class="menu-item" @click="$router.push({ path: '/Mine' });">
          <van-image class="menu-item-icon" src="img/mine/user.png">
            <template v-slot:loading>
              <van-loading type="spinner" />
            </template>
          </van-image>
          <span class="menu-item-label">{{ $t("my.personal_center") }}</span>
        </div>
        <div class="menu-item" @click="$router.push({ path: '/Personalreport' });">
          <van-image class="menu-item-icon" src="img/mine/baobiao.png">
            <template v-slot:loading>
              <van-loading type="spinner" />
            </template>
          </van-image>
          <span class="menu-item-label">{{ $t("my.my_statement") }}</span>
        </div>
        <div class="menu-item" @click="$router.push({ path: '/Moneylog' });">
          <van-image class="menu-item-icon" src="img/mine/mingxi.png">
            <template v-slot:loading>
              <van-loading type="spinner" />
            </template>
          </van-image>
          <span class="menu-item-label">{{ $t("my.account_detail") }}</span>
        </div>
        <div class="menu-item" @click="$router.push({ path: '/GameRecord' });">
          <van-image class="menu-item-icon" src="img/mine/youxi.png">
            <template v-slot:loading>
              <van-loading type="spinner" />
            </template>
          </van-image>
          <span class="menu-item-label">{{ $t("my.task_record") }}</span>
        </div>
        <div class="menu-item" @click="toNotice()">
          <van-image class="menu-item-icon" src="img/mine/gonggao.png">
            <template v-slot:loading>
              <van-loading type="spinner" />
            </template>
          </van-image>
          <span class="menu-item-label">{{ $t("my.information_announcement") }}</span>
        </div>
        <div class="menu-item" @click="toService()">
          <van-image class="menu-item-icon" src="img/mine/kefu.png">
            <template v-slot:loading>
              <van-loading type="spinner" />
            </template>
          </van-image>
          <span class="menu-item-label">{{ $t("my.online_service") }}</span>
        </div>
  
  
  
      </van-popup>
  
  
      <van-popup v-model="errorMoney" class="errorMoney" get-container="body" :close-on-popstate="true" :lock-scroll="true">
        <div class="header">
          tiếp tục<van-icon class="close" name="cross" @click="errorMoney = false;" />
        </div>
        <div class="content">{{ errorMoneyTxt }}</div>
        <!-- <div class="footer"></div> -->
      </van-popup>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        leftShow: false,
        notice: this.$t("index.loading"),
        banners: [{}],
        basicData: [],
        gameitem: [{}, {}, {}, {}],
        fenList: [{}, {}, {}, {}],
        movielist_0: [{}, {}, {}, {}],
        movielist_1: [],
        isLoading: true,
        movielistSwiperOption: {
          slidesPerView: 'auto',
          spaceBetween: 0,
          slidesPerGroup: 1,
        },
        bannerSwiperOption: {
          // effect: 'coverflow',
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: 'auto',
          speed: 800,
          autoplay: true,
          coverflowEffect: {
            rotate: 50,
            stretch: 10,
            depth: 100,
            modifier: 1,
            // slideShadows: true
          }
        },
        video_login: this.$store.getters.getBaseInfo.video_login || 1,
        token: localStorage.getItem('token'),
        isluckdraw: null,
        pop_content_txt: '',
        pop_content: false,
        kefu: '',
        iskefu: 0,
        homeMenuList: [{
          ico: require('@/assets/taskcp/home/ico1.png'),
          name: 'Recharge',
          path: 'Recharge'
        }, {
          ico: require('@/assets/taskcp/home/ico2.png'),
          name: 'GameRecord',
          path: 'GameRecord'
        },
        // {
        //   ico: require('@/assets/taskcp/home/ico3.png') ,
        //   name: 'promotion',
        //   path: 'promotion'
        // }, 
        {
          ico: require('@/assets/taskcp/home/ico4.png'),
          name: 'CSKH',
          path: 'kefu'
        }
        ],
        errorMoney: false,
        errorMoneyTxt: 'Kênh nạp tiền xin vui lòng liên hệ với dịch vụ khách hàng trực tuyến để nạp tiền',
  
      };
    },
    methods: {
      toKefu() {
        if (this.kefu=='') {
        this.$router.go(0);
       }else{
        window.location.href = this.kefu;
       }
      
        
      },
      toService() {
      if (this.$store.getters.getBaseInfo.iskefu == 1) {
        this.$router.push("ServiceOnline");
      } else {
        this.$toast.fail(this.$t("setting.forbid"));
      }
      this.leftShow=false;
    },
    toNotice() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Notice' })
      } else {
        this.$router.push({ path: '/Login' })
      }
      this.leftShow=false;
    },
      toLuckDraw() {
        this.$router.push({ path: '/luckDraw' })
      },
      gotoMenu(router) {
        this.$router.replace(router)
      },
      toMenu(v) {
        switch (v.path) {
          case 'Recharge':
            this.errorMoney = true;
            // this.$router.push({
            //   name:'Recharge',
            //   params:{
            //     // 'balance':this.userInfo.money
            //   }
            // })
            break;
          case 'GameRecord':
  
            this.$router.push({
              name: 'GameRecord',
              params: {
                // 'balance':this.userInfo.money
              }
            })
            break;
          case 'promotion':
  
            this.$router.push({
              name: 'promotion',
              params: {
                // 'balance':this.userInfo.money
              }
            })
            break;
          case 'kefu':
  
            this.toKefu()
            break;
  
  
          default:
            break;
        }
      },
      toLottery(key, id) {
        if (!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1) {
          this.$router.push({ path: '/Login' })
        } else {
          if (id) {
            this.$router.push({ path: '/Lottery1?key=' + key + "&id=" + id })
          }
        }
  
      },
      onRefresh() {
        setTimeout(() => {
          this.getBasicConfig();
          this.isLoading = false;
          this.$toast(this.$t('reservation.refresh'));
        }, 500);
      },
      getBasicConfig() {
        this.$http({
          method: 'get',
          url: 'sys_config'
        }).then(res => {
          this.basicData = res.data;
          this.getNotice(this.basicData);//获取公告
          this.getBanner(this.basicData);//获取banner
          this.getGameItem();//获取首页游戏列表
        })
  
      },
      getNotice(data) {
        this.notice = data.notice || '';
      },
      getGameItem() {
        this.$http({
          method: 'get',
          url: 'lottery_hot'
        }).then(res => {
          this.gameitem = res.data
        })
      },
      getBanner(data) {
        this.banners = data.banners;
      },
      query(selector) {
        return Array.from(document.querySelectorAll(selector));
      }
    },
    mounted() {
    },
    created() {
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res => {
        if (res.code == 200) {
  
          if (!localStorage.getItem('token') && res.data.video_login == 1) {
            return this.$router.push({ path: '/Login' })
          }
          console.log(res)
          this.isLoading = false
          this.isluckdraw = res.data.isluckdraw
          this.pop_content_txt = res.data.pop_content
          this.kefu = res.data.kefu
          this.iskefu = res.data.iskefu
          if (res.data.ispop == 1) {
            this.pop_content = true
          }
  
  
          this.$store.commit('setBaseInfoValue', res.data);
          this.getBasicConfig();
        }
      })
    }
  }
  
  </script>
  
  <style lang='less' scoped>
  @import "../../assets/css/base.css";
  @notice-bar-size: 30px;
  @movie-list-item-bottom-size: 25px;
  
  .linear-bg {
    height: 250px;
    background: linear-gradient(270deg, #4a90e2, #4a90e2);
  }
  
  .fen_box {
    margin: 0 25px;
  
    .title {
      color: #121738;
      font-size: 40px;
      font-weight: 500;
    }
  
    .list {
      .item_box {
        margin-top: 40px;
        background: white;
        border-radius: 20px;
        color: #747794;
        font-size: 36px;
        margin-bottom: 15px;
        padding: 30px;
        .username{
          font-size: 36px;
          color: #14a767;
        }
      }
    }
  }
  
  .applica_box {
  
    .title_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 40px 0;
      margin: 0 25px;
  
      .left {
        color: #121738;
        font-size: 32px;
        font-weight: 500;
      }
  
      .right {
        background-color: #4a90e2;
        color: white;
        padding: 3px 10px;
        border-radius: 35px;
  
      }
    }
  
    .content_box {
      background: linear-gradient(60deg, #448add, rgba(58, 128, 211, .678)), url('../../assets/home_02.jpg');
      background-size: 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      border-radius: 30px;
      margin: 60px 20px;
      padding: 40px;
      display: flex;
      flex-direction: row;
  
      .left {
        width: 120px;
        color: white;
        font-size: 44px;
        font-weight: 700;
        line-height: 60px;
        border-right: 1px solid white;
        padding-right: 20px;
      }
  
  
  
      .right {
        flex: 1;
        padding-left: 20px;
  
        .txt1 {
          color: white;
          font-size: 36px;
          font-weight: 500;
        }
  
        .func_btn {
          background: #4a90e2;
          border-radius: 20px;
          display: inline-block;
          padding: 10px 20px;
          text-transform: capitalize;
          margin-top: 20px;
          display: flex;
          width: 300px;
  
          .share {
            width: 40px;
          }
  
          .txt {
            color: white;
            font-size: 32px;
          }
        }
      }
    }
  }
  
  .recent_box {
    margin: 0 25px;
  
    .title_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 40px 0;
  
      .left {
        color: #121738;
        font-size: 32px;
        font-weight: 500;
      }
  
      .right {
        background-color: #4a90e2;
        color: white;
        padding: 3px 10px;
        border-radius: 35px;
  
      }
    }
  
    .content_box {
      .item_box {
        width: 90%;
        background: white;
        border-radius: 20px;
        padding: 30px;
        display: flex;
        flex-direction: column;
  
  
        .txt1 {
          display: inline-block;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #444756;
          font-size: 28px;
          font-weight: 600;
          text-align: left;
          margin-bottom: 10px;
        }
  
        .txt2 {
          width: 100%;
          color: #4a90e2;
          display: block;
          font-size: 28px;
          font-weight: 600;
          word-wrap: break-word;
          margin-bottom: 10px;
        }
  
        .txt3 {
          color: #444756;
          display: block;
          padding-top: 10px;
          font-size: 28px;
        }
  
        .progress_box {
          margin: 40px 0;
        }
      }
  
  
    }
  }
  
  .notice_box {
    background: linear-gradient(60deg, #448add, rgba(58, 128, 211, .678)), url('../../assets/home_01.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    border-radius: 30px;
    margin: 60px 20px;
    padding: 40px;
  
    .txt1 {
      font-size: 56px;
      font-weight: 700;
      margin-bottom: 5px;
      text-transform: capitalize;
      color: white;
    }
  
    .txt2 {
      color: var(--light);
      display: block;
      font-size: 44px;
      color: white;
      margin-top: 20px;
    }
  
    .txt3 {
      color: var(--light);
      display: block;
      font-size: 44px;
      color: white;
      margin-top: 10px;
    }
  
    .func_btn {
      background: #feb70b;
      border-radius: 20px;
      display: inline-block;
      font-size: 36px;
      line-height: 22px;
      margin-top: 40px;
      padding: 30px;
      text-transform: capitalize;
      color: white;
    }
  
  }
  
  .van-progress__pivot {
    display: none !important;
    font-size: 2.333vw !important;
  }
  
  .apptop-container {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f5f5f5;
    z-index: 20000;
  }
  
  .linear-gradient {
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
  }
  
  ::v-deep .van-notice-bar__left-icon,
  .van-notice-bar__right-icon {
    min-width: 40px;
  }
  
  .notice-swipe {
    width: calc(100% - 50px);
    height: 85px;
    font-size: @notice-bar-size;
  }
  
  ::v-deep .van-icon-bullhorn-o::before {
    transform: scale(2.5);
  }
  
  .banner {
    width: 100%;
    // margin-top: -23%;
  }
  
  .banner_swiper {
    height: 100%;
    width: 100%;
  
    .swiper-slide {
      // border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 60vw;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      background-color: #ffffff;
      background-position: center;
      background-size: cover;
      color: #ffffff;
    }
  }
  
  ::v-deep .swiper-container-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  }
  
  ::v-deep .swiper-container-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  }
  
  .banner_img {
    border-radius: 0px;
    width: 100%;
    height: 100%;
  }
  
  .hot-game {
    width: 100%;
    height: 100%;
  }
  
  .game_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 10px;
  
    .game_item {
      width: 31%;
      padding: 50px 0;
      margin-top: 30px;
      background: white;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      .game_img {
        width: 180px;
        height: 180px;
        border-radius: 100%;
      }
  
      .text1 {
        font-size: 26px;
        font-weight: 600;
        margin-top: 20px;
        height: 40px;
      }
  
      .text2 {
        font-size: 18px;
        margin-top: 20px;
      }
    }
  
  }
  
  .hot-title-div {
    width: calc(100% - 50px);
    margin: 0 auto;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .hot-title-div>div:first-child {
    width: 430px;
  }
  
  .hot-title-div div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .hot-title-div>div:nth-child(2) span {
    font-size: 20px;
    color: #979799;
  }
  
  .hot-title-div>div:first-child span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 10px;
    font-weight: 700;
    color: #000;
  }
  
  .hot-title-div>div:first-child span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 28px;
    font-weight: 700;
    color: #000;
  }
  
  .hot-title-div>div:nth-child(2) span {
    font-size: 25px;
    color: #979799;
  }
  
  .hot-title-div>div:first-child span:before {
    content: "";
    display: block;
    width: 5px;
    height: 30px;
    background-color: #4a90e2;
    border-radius: 1px;
    margin-right: 5px;
  }
  
  .hot-game .hot-items-div {
    margin-top: -3px;
  }
  
  .hot-game .hot-items-div span {
    margin-top: 10px;
    font-size: 28px;
    color: #000;
  }
  
  .hot-recommend {
    width: 100%;
    flex: 1;
    background-color: #f2f2f5;
  }
  
  .movie_swiper {
    .swiper-slide {
      width: 80%;
    }
  }
  
  .movie_list_0 {
    width: calc(100% - 50px);
    margin: 0 auto;
  }
  
  .movie_cover {
    border-radius: 10px;
    width: 550px;
    height: 330px
  }
  
  .movie_list_0 .movie-list-item-bottom {
    position: relative;
    width: 550px;
    bottom: 43px;
  }
  
  .movie_list_0 .movie-list-item-bottom .movie-time-div {
    background-color: rgba(0, 0, 0, .4);
  }
  
  .movie_list_0 .movie-list-item-bottom>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
    font-size: 28px;
    color: #fff;
  }
  
  .movie_list_0 .movie-time-div {
    color: #fff;
    border-radius: 0 0 10px 10px;
  }
  
  .movie_list_0 .movie_swiper .hot {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 80px;
  }
  
  .movie_list_0 span {
    font-size: 30px;
  }
  
  .movie_list_1 {
    display: flex;
    width: calc(100% - 50px);
    margin: 0 auto;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  
  .movie_list_1 .movie-list-item .cover_img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }
  
  .home-scroll {
    padding-bottom: 110px;
  }
  
  .movie_list_1 .movie-list-item {
    margin-bottom: 10px;
    width: 335px;
    height: 290px;
  }
  
  .movie_list_1 .movie-list-item-bottom {
    position: relative;
    width: 335px;
    bottom: 42px;
  
  }
  
  .movie_list_1 .movie-list-item:nth-child(odd) {
    margin-right: 10px;
  }
  
  .movie_list_1 .movie-list-item-bottom .movie-time-div {
    background-color: rgba(0, 0, 0, .4);
  }
  
  .movie_list_1 .movie-list-item-bottom>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
    color: #fff;
  }
  
  .movie_list_1 .movie-time-div {
    color: #fff;
    border-radius: 0 0 10px 10px;
    height: 35px;
  }
  
  .movie_list_1 .movie_swiper .hot {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 5px;
  }
  
  .movie_list_1 .movie-list-item .movie-time-div span:first-child {
    overflow: hidden;
    white-space: nowrap;
    width: 180px;
    padding-left: 8px;
    font-size: 25px;
  }
  
  .movie_list_1 .movie-list-item .movie-time-div span:last-child {
    overflow: hidden;
    white-space: nowrap;
    width: 0px;
    padding-right: 110px;
    font-size: 22px;
  }
  
  .movie_list_0 .movie-time-div span:first-child {
    overflow: hidden;
    white-space: nowrap;
    width: 350px;
    padding-left: 10px;
    font-size: 25px;
  }
  
  .hot-recommend-more {
    width: 100%;
    padding-bottom: 20px;
    text-align: center;
    color: #979799;
    font-size: 30px;
  }
  
  .hot-items-div .game_item_img {
    width: 100px;
    height: 100px;
  }
  
  ::v-deep .hot-items-div .game_item_img .van-image__img {
    border-radius: 20px;
  }
  
  ::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
    color: #000000;
    font-size: 35px;
  }
  
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .luckDraw {
    position: fixed;
    bottom: 160px;
    right: 40px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    z-index: 999;
  }
  
  .kefu {
    position: fixed;
    bottom: 300px;
    right: 60px;
    width: 80px;
    height: 80px;
    z-index: 999;
    // background: #fff;border-radius: 50px;
  }
  
  /deep/.van-popup__close-icon {
    font-size: 50px;
  }
  
  
  .header_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    background: linear-gradient(-180deg, #4a90e2, #4a90e2);
    height: 120px;
    // padding: 0 30px;
    color: #fff;
    font-weight: bold;
    font-size: 50px;
  }
  
  .Logo_NK {
    height: 70px;
  }
  
  .left_ico {
    padding-left: 30px;
    width: 70px;
    height: 40px;
  }
  
  .right_ico {
    padding-right: 4vw;
    width: 11.333vw;
    height: 7.333vw;
  }
  
  .menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 130px;
    padding: 20px;
    color: #fff;
  }
  
  .btn_hot {
    text-align: center;
    justify-content: left;
    align-items: center;
    margin: 30px 0 0 25px;
    font-size: 30px;
  
    .img {
      width: 28px;
      height: 28px;
      margin-right: 16px;
    }
  
    .txt {
      color: #121738;
      font-size: 40px;
      font-weight: 500;
    }
  }
  
  .menu-item-icon {
    margin-right: 16px;
    width: 60px;
  }</style>
  