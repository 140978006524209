<template>
  <div class="container page">
    <van-nav-bar :title="$t('taskcp.EventCollection')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main">

      <div class="taskcp_table">
            <div class="taskcp_table_list" >
              {{ $t('taskcp.EventCollection_table1') }}
            </div>
            <div class="taskcp_table_list" >
              {{ $t('taskcp.EventCollection_table2') }}
            </div>
            <div class="taskcp_table_list" >
              {{ $t('taskcp.EventCollection_table3') }}
            </div>
            <div class="taskcp_table_list" >
              {{ $t('taskcp.EventCollection_table4') }}
            </div>
      </div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')" :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
        <van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
        <div v-else class="item_list" v-for="(v,key) in list" :key="key">

          1
        </div> 
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list:[],
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t("reservation.refresh"));
        this.isLoading = false;
      }, 500);
    },
    getUserGameList(){
      this.$http({
        method: 'get',
        url: 'user_get_game_list'
      }).then(res => {
        if (res.code === 200) {
          console.log(res.data)
          this.list = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }

  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserGameList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
</style>
