<template>
    <div class="container page">
      <van-nav-bar :title="$t('taskcp.InformationManage')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
      <div class="main">
        <div class="taskcp_tab">
          <!-- <div class="taskcp_tab_list">
          </div> -->
          <van-tabs v-model="taskcp_tab_active" animated sticky line-width="100px" :swipeable="true">
            <van-tab :title="item.name" v-for="(item,index) in taskcp_tab_list" :key="index"></van-tab>
          </van-tabs>
        </div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')" :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
          <van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
          <div v-else class="item_list" v-for="(v,key) in list" :key="key">

            1
          </div> 
        </van-pull-refresh>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isLoading: false,
        list:[],
        taskcp_tab_active:0,
        taskcp_tab_list:[
          {
          name:this.$t("taskcp.member1")
        },
          {
          name:this.$t("taskcp.member2")
        },{
          name:this.$t("taskcp.member3")
        }]
      };
    },
    methods: {
      back(){
        return window.history.back();
      },
      onRefresh() {
        setTimeout(() => {
          this.$toast(this.$t("reservation.refresh"));
          this.isLoading = false;
        }, 500);
      },
      getUserGameList(){
        this.$http({
          method: 'get',
          url: 'user_get_game_list'
        }).then(res => {
          if (res.code === 200) {
            console.log(res.data)
            this.list = res.data;
          }else if(res.code ===401){
            this.$toast(res.msg);
          }
        })
      }
  
    },
    created() {
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.getUserGameList();
      }
    }
  };
  </script>
  
  <style lang='less' scoped>
  @import "../../assets/css/base.css";


::v-deep .van-tab {
	font-size: 30px;
	line-height: 35px;
    text-align: center;
	font-weight: bold;
}
::v-deep .van-tab__text--ellipsis{

  -webkit-line-clamp: 2;
}
::v-deep .van-tabs__line {
	background-color: #4a90e2;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
  </style>
  