<template>
    <div class="tel home">

        <van-nav-bar class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
            <!--      <template #right>-->
            <!--        <div class="right">切换任务</div>-->
            <!--      </template>-->
        </van-nav-bar>
        <div class="yqhy">Mời bạn bè nhận hoa hồng</div>
        <div class="content">
            <div class="top">
                <!-- <div class="title">
                    <img :src="require('@/assets/images/news/money.png')" alt="" class="img" width="20">
                    <img :src="require('@/assets/images/news/money.png')" alt="" class="img" width="20">
                    <img :src="require('@/assets/images/news/money.png')" alt="" class="img" width="20">
                </div> -->
                <!-- <div class="c" v-html="a_content"></div> -->
                <!-- <div class="b">
                    Mã mời <span class="span" id="meetText">123</span>
                </div> -->
                <div class="bottom" @click="copy()">Sao chép liên kết mời</div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {

        };
    },
    methods: {
        back() {
            return window.history.back();
        },
        copy() {
            console.log(location.host+'/#/?invite_code='+123456)
            let input = document.createElement('input')
            input.setAttribute('readonly', 'readonly') // 防止手机上弹出软键盘
            // const meetText = document.getElementById('meetText').innerText
            input.setAttribute('value', location.host+'/#/?invite_code='+localStorage.getItem("incod") || '')
            document.body.appendChild(input)
            input.select()
            document.execCommand('copy')
            document.body.removeChild(input)
            this.$toast('Sao chép thành công');
        }
    }
};
</script>
<style lang="less" scoped>
.tel {
    overflow: hidden;
    // background-image: url("~@/assets/images/self/share.png");
    // background-size: 100% 100%;
    position: relative;
    background-color: #4a90e2;
    height: 100vh;
    text-align: center;

    .yqhy {
        height: 400px;
        width: 100%;
        background-color: #4a90e2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        font-size: 50px;
        padding-top: 60px;
    }

    :deep(.van-nav-bar) {
        position: absolute !important;
        width: 100%;
        background-color: inherit;
        color: #fff;
        z-index: 10;

        .van-nav-bar__left {
            .van-icon {
                color: #fff;
            }
        }

        .van-nav-bar__title {
            color: #fff;
        }

        .van-nav-bar__right {
            .van-icon {
                color: #fff;
            }
        }
    }

    .content {
        width: 100%;
        padding: 0 25px;
        border-radius: 30px;
        flex: 1;
        overflow: auto;

        // background-color: #fff;
        .bottom {
            width: 100%;
            height: 74px;
            line-height: 74px;
            font-size: 32px;
            background-color: #4a90e2;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            margin-top: 20px;
        }

        .top {
            padding: 20px;
            background-color: #fff;
            border-radius: 20px;

            .title {
                margin-bottom: 20px;
            }

            .c {
                font-size: 22px;
                line-height: 2;
                text-indent: 2em;
                color: #666;
                margin-bottom: 20px;
            }

            .b {
                width: 290px;
                margin: 0 auto;
                font-size: 26px;
                color: #333;
                position: relative;

                .span {
                    margin-left: 5px;
                }
            }
        }
    }
}</style>