import Vue from 'vue'
import VueRouter from 'vue-router'
//首页
import Home from '../pages/home/index.vue'/* 首页 */
import Mine from '../pages/mine/index.vue'/* 我的 */
import Choose from '../pages/choose/index.vue'/* 选妃 */
import List from '../pages/choose/list.vue'/* 选妃列表 */
import Profile from '../pages/choose/profile.vue'/* 选妃详情 */
import Video from '../pages/video/index.vue'/* 视频 */
import Game from '../pages/game/index.vue'/* 游戏 */
import Login from '../pages/login/index.vue'/* 登录 */
import Register from '../pages/login/register.vue'/* 注册 */
import ServiceOnline from '../pages/mine/ServiceOnline.vue'/* 客服列表 */
import ServicePage from '../pages/mine/ServicePage.vue'/* 客服详情界面 */
import Setting from '../pages/mine/Setting.vue'/* 设置 */
import Infomation from '../pages/mine/Infomation.vue'/* 基本信息 */
import Setname from '../pages/mine/Setname.vue'/* 修改姓名 */
import Language from '../pages/mine/Language.vue'/* 语言选择 */
import Setsex from '../pages/mine/Setsex.vue'/* 修改姓名 */
import Recharge from '../pages/mine/Recharge.vue'/* 充值 */
import SetPayPassword from '../pages/mine/SetPayPassword.vue'/* 修改提现密码 */
import EditPayPassword from '../pages/mine/EditPayPassword.vue'/* 修改提现密码 */

import SetLoginPassword from '../pages/mine/SetLoginPassword.vue'/* 修改提现密码 */
import Lottery from '../pages/lottery/index.vue'/* 彩票详情 */
import Notice from '../pages/mine/Notice.vue'/* 公告 */
import PlayVideo from '../pages/video/PlayVideo'/* 视频播放页面 */
import Setbank from '../pages/mine/Setbank'/* 视频播放页面 */
import BindCard from '../pages/mine/BindCard'/* 绑定银行卡界面 */
import Withdraw from '../pages/mine/Withdraw'/* 绑定银行卡界面 */
import Personalreport from '../pages/mine/Personalreport'/* 个人表报 */
import GameRecord from '../pages/mine/GameRecord'/* 游戏记录 */
import WithdrawRecord from '../pages/mine/WithdrawRecord'/* 提现记录 */
import RechargeRecord from '../pages/mine/RechargeRecord'/* 充值记录 */
import Moneylog from '../pages/mine/Moneylog'/* 资金明细 */
import Channel from '../pages/mine/Channel'/* 银行卡信息 */
import nHomeList from '../pages/nHome/list'/* 银行卡信息 */
import nHomeDetail from '../pages/nHome/detail'/* 银行卡信息 */
import luckDraw from '../pages/luckDraw/index'/* 抽奖页 */

import bookingRecord from '../pages/taskcp/bookingRecord'
import WithdrawalHistory from '../pages/taskcp/WithdrawalHistory'
import DepositHistory from '../pages/taskcp/DepositHistory'
import EventCollection from '../pages/taskcp/EventCollection'

import promotion from '../pages/taskcp/promotion'
import bookRoom from '../pages/taskcp/bookRoom'
import InformationManage from '../pages/taskcp/InformationManage'
import vip from '../pages/taskcp/vip'
import accountChange from '../pages/taskcp/accountChange'
import PersonalReport1 from '../pages/taskcp/PersonalReport1'
import lottery1 from '../pages/taskcp/lottery'
import share from '../pages/mine/share'



Vue.use(VueRouter)
const routes = [
  { path: '/', redirect: 'login', component: Login, meta: { title: '' } },
  // {path:'/',redirect:'/Home',component:Home,meta:{title:''}},
  { path: '/Home', name: 'home', component: Home, meta: { title: '' } },
  { path: '/nHomeList', name: 'nHomeList', component: nHomeList, meta: { title: '' } },
  { path: '/nHomeDetail', name: 'nHomeDetail', component: nHomeDetail, meta: { title: '' } },
  { path: '/Choose', name: 'choose', component: Choose, meta: { title: '' } },
  { path: '/List', name: 'list', component: List, meta: { title: '' } },
  { path: '/Profile', name: 'profile', component: Profile, meta: { title: '' } },
  { path: '/Mine', name: 'mine', component: Mine, meta: { title: '' } },
  { path: '/Video', name: 'video', component: Video, meta: { title: '' } },
  { path: '/Game', name: 'game', component: Game, meta: { title: '' } },
  { path: '/Login', name: 'login', component: Login, meta: { title: '' } },
  { path: '/Register', name: 'register', component: Register, meta: { title: '' } },
  { path: '/ServiceOnline', name: 'ServiceOnline', component: ServiceOnline, meta: { title: '' } },
  { path: '/ServicePage', name: 'ServicePage', component: ServicePage, meta: { title: '' } },
  { path: '/Setting', name: 'Setting', component: Setting, meta: { title: '' } },
  { path: '/Infomation', name: 'Infomation', component: Infomation, meta: { title: '' } },
  { path: '/Setname', name: 'Setname', component: Setname, meta: { title: '' } },
  { path: '/Setsex', name: 'Setsex', component: Setsex, meta: { title: '' } },
  { path: '/Language', name: 'Language', component: Language, meta: { title: '' } },
  { path: '/Recharge', name: 'Recharge', component: Recharge, meta: { title: '' } },
  { path: '/SetPayPassword', name: 'SetPayPassword', component: SetPayPassword, meta: { title: '' } },
  { path: '/EditPayPassword', name: 'EditPayPassword', component: EditPayPassword, meta: { title: '' } },

  { path: '/SetLoginPassword', name: 'SetLoginPassword', component: SetLoginPassword, meta: { title: '' } },
  { path: '/Lottery', name: 'Lottery', component: Lottery, meta: { title: '' } },
  { path: '/Notice', name: 'Notice', component: Notice, meta: { title: '' } },
  { path: '/PlayVideo', name: 'PlayVideo', component: PlayVideo, meta: { title: '' } },
  { path: '/Setbank', name: 'Setbank', component: Setbank, meta: { title: '' } },
  { path: '/BindCard', name: 'BindCard', component: BindCard, meta: { title: '' } },
  { path: '/Withdraw', name: 'Withdraw', component: Withdraw, meta: { title: '' } },
  { path: '/Personalreport', name: 'Personalreport', component: Personalreport, meta: { title: '' } },
  { path: '/WithdrawRecord', name: 'WithdrawRecord', component: WithdrawRecord, meta: { title: '' } },
  { path: '/GameRecord', name: 'GameRecord', component: GameRecord, meta: { title: '' } },
  { path: '/RechargeRecord', name: 'RechargeRecord', component: RechargeRecord, meta: { title: '' } },
  { path: '/Moneylog', name: 'Moneylog', component: Moneylog, meta: { title: '' } },
  { path: '/Channel', name: 'Channel', component: Channel, meta: { title: '' } },
  { path: '/luckDraw', name: 'luckDraw', component: luckDraw, meta: { title: '' } },

  { path: '/bookingRecord', name: 'bookingRecord', component: bookingRecord, meta: { title: '' } },
  { path: '/WithdrawalHistory', name: 'WithdrawalHistory', component: WithdrawalHistory, meta: { title: '' } },

  { path: '/promotion', name: 'promotion', component: promotion, meta: { title: '' } },
  { path: '/bookRoom', name: 'bookRoom', component: bookRoom, meta: { title: '' } },
  { path: '/InformationManage', name: 'InformationManage', component: InformationManage, meta: { title: '' } },
  { path: '/vip', name: 'vip', component: vip, meta: { title: '' } },
  { path: '/accountChange', name: 'accountChange', component: accountChange, meta: { title: '' } },
  { path: '/PersonalReport1', name: 'PersonalReport1', component: PersonalReport1, meta: { title: '' } },
  { path: '/DepositHistory', name: 'DepositHistory', component: DepositHistory, meta: { title: '' } },
  { path: '/EventCollection', name: 'EventCollection', component: EventCollection, meta: { title: '' } },
  { path: '/lottery1', name: 'lottery1', component: lottery1, meta: { title: '' } },
  { path: '/share', name: 'share', component: share, meta: { title: '' } },



];

//生成路由实例
const router = new VueRouter({
  routes
})
// router.beforeEach((to, from, next) => {         //修改标题路由配置加上这个
//   document.title = to.matched[0].meta.title
//   // if (to.path == from.path) return
//   next()
// })
//先把VueRouter原型对象的push保存一份
// const originPush = VueRouter.prototype.push
// //重写push方法
// VueRouter.prototype.push = function (location, resolve, reject) {
//   //调用保存的push方法
//   //但是保存的push方法是挂载在window的方法 所以要通过call修改this的指向
//   if (resolve && reject) {
//     originPush.call(this, location, resolve, reject);
//   } else {
//     originPush.call(this, location, () => { }, () => { });
//   }
// }


// 4. 你还可以监听路由拦截，比如权限验证。
router.beforeEach((to, from, next) => {
  // 1. 每个条件执行后都要跟上 next() 或 使用路由跳转 api 否则页面就会停留一动不动
  // 2. 要合理的搭配条件语句，避免出现路由死循环。
  var token = localStorage.getItem('token')
  if (to.name == 'home' || to.name == 'login' || to.name == 'register' || to.name == 'tel'|| to.name == 'language') {
    next()
  } else if (!token) {
    next('/login')
  } else {
    next()
  }
})

export default router