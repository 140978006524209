<template>
  <div class="lottery_container" style="background-color: #f5f5f5; min-height: 100vh; height: 100%;">
    <AppTop />
    <!-- 游戏详情 -->
    <div class="games_info_box">
      <div class="left">
        <img class="left_ico" :style="rotateStyle" :src="$route.query.ico" alt="">
      </div>
      <div class="right">
        <div class="title">Kết quả đơn kết hợp</div>
        <div class="center_box">
          <div class="time" style="color: #fff;">
            
          </div>
          <div class="code">Kỳ:{{ new Date().getTime()/1000 | dateFormat  }}{{this.lottery.next_expect.substr(this.lottery.next_expect.length - 3, this.lottery.next_expect.length) }} Đang
            Mở...</div>
          <div class="time">
            <van-count-down :time="time" @finish="check()" />
          </div>
        </div>
        <div class="bottom_box">
          <div class="func_btn">__ __</div>
          <div class="func_btn">__ __</div>
        </div>
      </div>
    </div>
    <!-- 历史开始 -->
    <div class="record_trans_bix">
      <div class="title">Kết quả kỳ trước:</div>
      <div class="list">
        <div class="item_box" v-for="(v, key) in record_list" :key="key">
          

          <div class="txt1" v-if="key == 0">Kỳ: {{ v.create_time | dateFormat  }}{{ v.expect.substr(v.expect.length - 3, v.expect.length) }}</div>
          <div class="txt1" v-if="key == 0">ĐơnNhỏ: {{ v.one }}  ĐơnLẻ: {{ v.two }}</div>
          <div class="txt" v-if="key != 0">Kỳ: {{ v.create_time | dateFormat  }}{{ v.expect.substr(v.expect.length - 3, v.expect.length) }}</div>
          <div class="txt" v-if="key != 0">ĐơnNhỏ: {{ v.one }}  ĐơnLẻ: {{ v.two }}</div>
        </div>
      </div>
    </div>

    <!-- 选择购买  -->
    <div class="select_box">
      <div class="top">
        <div class="left">Chọn kênh:</div>
        <div class="right">Tổng đơn: <span class="span">{{ formData.length }}</span> Tổng tiền: <span class="span">{{
          Number(currertMoney) != 0 ? Number(currertMoney) / 1000 : 0 }}K</span></div>
      </div>

      <div class="select_list">
        <div class="item_box" v-for="(v, index) in lottery_peilv_list" :key="index">
          <div class="item_container" :style="{ backgroundColor: isSelected(index) ? '#4a90e2' : 'white' }"
            @click="toggleSelection(index)">
            <div class="number"
              :style="{ background: isSelected(index) ? 'linear-gradient(180deg, #edba2e, #fcde58, #edba2e, rgba(237, 186, 46, .639), rgba(181, 88, 252, 0))' : 'transparent' }">
              {{ isSelected(index) ? Number(v.number) / 1000 + "k" : '' }}</div>
            <img class="sel_ico" :src="$route.query.ico" alt="">
            <div class="level" :style="{ color: isSelected(index) ? 'white' : '#4a90e2' }">{{ v.name }}</div>
            <!-- <div class="minnumber">trả tiền 2000.0</div> -->
          </div>

        </div>
      </div>
    </div>

    <!-- 购买按钮 -->
    <div class="buy_box">
      <!-- <div class="func_btn" @click="doSub" :disabled="true">Xác nhận đơn hàng</div> -->
      <van-button class="func_btn" :disabled="isDisabled" type="default" @click="doSub">Xác nhận đơn hàng</van-button>
    </div>


    <van-popup v-model="showFg" class="popupBlance"
      :style="{ width: '75%', background: 'linear-gradient(-180deg,#4a90e2,#4a90e2)', padding: '15px' }" closeable
      close-icon-position="top-right">
      <div class="blance_input_box">
        <div class="title">Nhập số tiền</div>
        <div class="center_box">
          <div class="left" @click="addAmount">+</div>
          <div class="imput_div">
            <input v-model="amount" type="number" class="input_main" placeholder="">
          </div>
          <div class="right" @click="delAmount">-</div>
        </div>
        <div class="bottom_box">
          <div class="func_btn" @click="clickPopupOK(selectIndex)">OK</div>
          <div class="func_btn1" @click="clickPopupNO(selectIndex)">Bỏ</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
  
<script>
import AppTop from '../AppTop/AppTop.vue'
// var time;
// var count = 0;

export default {
  data() {
    return {
      notice: this.$t("index.loading"),
      jiesuanpage: false,
      isRotating: false,
      rotationDegree: 0,
      choose: {
        bi: false,
        sm: false,
        si: false,
        do: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
        13: false,
        14: false,
        15: false,
        16: false,
        17: false,
        18: false,
      },
      playgame: false,
      shopping: false,
      isLoading: false,
      amount: 0,
      showFg: false,
      play: {},
      lottery_peilv_list: {},
      selectedItems: [],
      lottery_list: {},
      record_list: {},
      active: false,
      userInfo: {},
      lottery: {},
      time: null,
      shopchoose: this.$t("reservation.no_choose"),
      gameitem: "",
      formData: [],
      money: "",
      currertMoney: 0,
      isDisabled: false,

      taskcp_tab_active: 0,
      taskcp_tab_list: [
        {
          name: this.$t("taskcp.lottery_tab1"),
        },
        {
          name: this.$t("taskcp.lottery_tab2"),
        },
        {
          name: this.$t("taskcp.lottery_tab3"),
        },
      ],
      taskcp_tab2_1_active: 0,
      taskcp_tab2_1: [
        {
          name: this.$t("taskcp.lottery_table2_1"),
        },
        {
          name: this.$t("taskcp.lottery_table2_2"),
        },
        {
          name: this.$t("taskcp.lottery_table2_3"),
        },
      ],
      taskcp_tab2_2_active: 0,
      taskcp_tab2_2: [
        {
          name: this.$t("taskcp.lottery_table2_1_1"),
        },
        {
          name: this.$t("taskcp.lottery_table2_1_2"),
        },
        {
          name: this.$t("taskcp.lottery_table2_1_3"),
        },
      ],
      loglist_1: {
        list: []
      },
      game_list: [],
      game_notice: [],
      game_id: "",
      selectIndex: 0,
    };
  },
  filters: {
    dateFormat(val) {
      // 先把传参毫秒转化为new Date()
      const dt = new Date(val * 1000)
      const y = dt.getFullYear()
      // 月份是从0开始,需要+1
      // +''是把数字转化为字符串,padStart(2,'0')是把字符串设置为2位数,不足2位则在开头加'0'
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      // var hour = (dt.getHours() + '').padStart(2, '0');
      // var minute = (dt.getMinutes() + '').padStart(2, '0');
      // var second = (dt.getSeconds() + '').padStart(2, '0');
      return `${d}${m}${y}`
    }
  },
  computed: {
    rotateStyle() {
      return {
        transform: `rotate(${this.rotationDegree}deg)`
      };
    }
  },
  beforeDestroy() {
    this.isRotating = false; // 在组件销毁前停止动画
  },
  methods: {
    startRotation() {
      this.isRotating = true;
      this.rotate();
    },

    rotate() {
      if (this.isRotating) {
        this.rotationDegree += 1;
        if (this.rotationDegree >= 360) {
          this.rotationDegree = 0;
        }
        requestAnimationFrame(this.rotate);
      }
    },
    isSelected(index) {
      return this.selectedItems.indexOf(index) !== -1;
    },
    toggleSelection(index) {
      //点击item  弹出弹窗
      this.showFg = true;
      this.selectIndex = index;

    },
    clickPopupOK(index) {
      //判断 如果已经选中的话 就不能在选了
      if (this.selectedItems.length > 0) {
        for (var i = 0; i < this.selectedItems.length; i++) {
          if (index == this.selectedItems[i]) {
            this.$toast(this.$t("不能重复选择"));
            return;
          }
        }
      }
      if (this.amount > 0) {
        this.showFg = false;
        const selectedIndex = this.selectedItems.indexOf(index);
        if (selectedIndex !== -1) {
          this.selectedItems.splice(selectedIndex, 1);
        } else {
          this.selectedItems.push(index);

        }
      } else {
        this.$toast(this.$t("金额不能为0"));
      }
      this.lottery_peilv_list[index].number = this.amount;
      this.formData.push({ name: this.lottery_peilv_list[index].name, type: this.lottery_peilv_list[index].type, number: this.amount });
      this.currertMoney += Number(this.lottery_peilv_list[index].number);
      console.log("wocaoii", this.lottery_peilv_list);

    },
    clickPopupNO(index) {
      this.showFg = false;
      if (this.selectedItems.length > 0) {
        const selectedIndex = this.selectedItems.indexOf(index);
        if (selectedIndex !== -1) {
          this.selectedItems.splice(selectedIndex, 1);

          for (var i = 0; i < this.formData.length; i++) {
            if (this.lottery_peilv_list[index].type === this.formData[i]["type"]) {
              this.formData.splice(i, 1);
            }
          }
          this.currertMoney -= Number(this.lottery_peilv_list[index].number);
          this.lottery_peilv_list[index].number = '';
        } else {
          this.selectedItems.push(index);
        }

      }



    },
    //加号
    addAmount() {
      this.amount = Number(this.amount) + 10000;
    },

    //减号

    delAmount() {
      if (this.amount >= 10000) {
        this.amount = this.amount - 10000;
      }

    },



    getTotleWin(index) {
      return this.loglist_1.list.filter(item => item.one_number == index).length
    },
    getContinuousLow(index) {
      let arr = this.loglist_1.list.reduce((acc, n, idx) => {
        if (n.one_number != index) {
          acc.push(idx);
        }
        return acc;
      }, []);
      let oldMax = 0;
      let max = 0;
      if (arr.length > 0) {
        max = 1
      }
      for (let i = 0; i < arr.length; i++) {

        if (arr[i + 1] - arr[i] == 1) {
          ++max
        } else {
          if (max > oldMax) {
            oldMax = max;
          }
          max = 1;
        }
      }
      return oldMax
    },



    back() {
      return window.history.back();
    },

    doSub() {
      //计算  金额  与 金额  type

      this.money = '';
      this.gameitem = '';
      console.log("cainim", this.formData);

      if (this.formData.length === 0) {
        this.$toast(this.$t("reservation.choose_num"));
        return false;
      } else {

        for (var i = 0; i < this.formData.length; i++) {
          this.gameitem += "," + this.formData[i].type;
          this.money += "," + this.formData[i].number;

        }
        if (this.userInfo.money - this.money * this.formData.length < 0) {
          this.$toast(this.$t("reservation.balance_enough"));
          return false;
        } else {
          if (this.isDisabled) return;
          this.isDisabled = true;
          this.$http({
            method: "post",
            data: {
              item: this.gameitem.substr(1,this.gameitem.length),
              money: this.currertMoney,
              lid: this.lottery.id,
              mid: this.userInfo.id,
              expect: this.lottery.now_expect,
            },
            url: "game_place_order",
          }).then((res) => {
            if (res.code === 200) {
              this.$toast(res.msg);
              this.allClear();
              this.getUserInfo();

            } else if (res.code === 401) {
              this.$toast(res.msg);
            }
            this.isDisabled = false;
          });
          return true;
        }
      }
    },
    allClear() {
      for (var i = 0; i < this.formData.length; i++) {
        this.choose[this.formData[i]["type"]] = false;
      }
      this.formData.length = 0;
      this.money = "";
      this.shopchoose = this.$t("reservation.no_choose");
      this.gameitem = "";
      this.shopping = false;
      this.jiesuanpage = false;
      this.currertMoney = 0;
      this.money = '';
      this.formData =[];
      this.amount = 0;
      this.selectedItems=[];
    },
    jiesuan() {
      if (this.formData.length === 0) {
        this.$toast(this.$t("reservation.choose_num"));
        return false;
      } else if (this.money === "") {
        this.$toast(this.$t("reservation.price_place"));
        return false;
      } else {
        this.jiesuanpage
          ? (this.jiesuanpage = false)
          : (this.jiesuanpage = true);
      }
    },

    getUserGameList() {
      this.$http({
        method: "get",
        data: { game_id: this.game_id },
        url: "user_get_game_list",
      }).then((res) => {
        if (res.code === 200) {
          this.game_list = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },


    getBasicConfig() {
      this.$http({
        method: "get",
        url: "sys_config",
      }).then((res) => {
        this.notice = res.data.notice || "";
      });
    },
    check() {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        // time = window.setInterval(() => {
        //   setTimeout(()=>{
        //     count++;
        //     if(count > 5){
        //       clearInterval(time);
        //       count = 0;
        //     }
        //   },0)
        // }, 300)

        this.getUserInfo();
        this.getLotteryInfo();
        this.getLotteryList();
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t("reservation.refresh"));
        this.getLotteryList();
        this.isLoading = false;
      }, 200);
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info",
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getLotteryPeilv() {
      this.$http({
        method: "get",
        data: { id: this.$route.query.id },
        url: "lottery_get_peilv",
      }).then((res) => {
        if (res.code === 200) {
          this.lottery_peilv_list = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getLotteryList() {
      this.$http({
        method: "get",
        data: { key: this.$route.query.key },
        url: "lottery_get_one_list",
      }).then((res) => {
        if (res.code === 200) {
          this.lottery_list = res.data;
          if (this.lottery_list.length > 5) {
            this.record_list = this.lottery_list.slice(0, 5);
          } else {
            this.record_list = this.lottery_list;
          }
          console.log('111111111111',this.record_list);
          this.getLotteryPeilv();
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getLotteryInfo() {
      this.$http({
        method: "get",
        data: { key: this.$route.query.key },
        url: "lottery_get_info",
      }).then((res) => {
        if (res.code === 200) {
          console.log(res.data)
          this.game_id = res.data.id;
          this.$http({
            method: "get",
            url: "noticelist",
            data: { id: res.data.id },
          }).then((ret) => {
            this.game_notice = ret.data || "";
          });
          this.getUserGameList(res.data.id);
          // if(parseFloat(this.userInfo.money) < parseFloat(res.data.condition)){
          //   this.$toast(this.$t("reservation.contact_admin"));
          //   this.$router.push({path:'/ '})
          //   return false;
          // }
          this.lottery = res.data;
          console.log(res.data,88889)
          this.time = res.data.second * 1000;

          if (this.time / 1000 === 59) {
            this.$toast(
              this.$t("reservation.prize_succ") + this.lottery.now_expect
            );
          }
        } else if (res.code === 401) {
          this.$toast({
            message: res.msg,
            duration: 3000,
          });
        }
      });
    },


  },
  created() {
    this.startRotation();
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getUserInfo();
      this.getLotteryInfo();
      this.getLotteryList();
      this.getBasicConfig();
    }
  },
  destroyed() {
    // clearInterval(time);
  },

  components: {
    AppTop
  }
};
</script>
  
<style lang='less' scoped>
@import "../../assets/css/base.css";

.buy_box {
  margin: 0 25px;
  margin-top: 80px;

  .func_btn {
    background-color: #feb70b;
    height: 100px;
    color: white;
    font-size: 36px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 100%;
  }
}

.popupBlance {
  border-radius: 20px;

  .blance_input_box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 15px;

    .title {
      color: white;
      font-size: 28px;
      font-weight: 600;
    }

    .center_box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        background: linear-gradient(180deg, #edba2e, #fcde58, #edba2e, #edba2e, #fcde58);
        width: 80px;
        height: 60px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 38px;
        color: black;
      }

      .imput_div {
        width: 100%;
        margin-top: 4px;
        border-radius: 50px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        margin: 40px 20px;

        .input_main {
          height: 50px;
          border: 0px;

          outline: none;
          background: none;
          line-height: 50px;

          color: #d30e0e;
          text-align: center;
          font-weight: 600;
          width: 100%;

          ::-webkit-input-placeholder {
            color: #d30e0e;
            font-size: 15px;
          }
        }
      }

      .right {
        background: linear-gradient(180deg, #edba2e, #fcde58, #edba2e, #edba2e, #fcde58);
        width: 80px;
        height: 60px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 38px;
        color: black;
      }
    }

    .bottom_box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;

      .func_btn {
        width: 40%;
        height: 60px;
        background: linear-gradient(45deg, #edba2e, #fcde58, #edba2e, #edba2e, #fcde58);
        color: #212529;
        font-size: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
      }

      .func_btn1 {
        width: 45%;
        height: 60px;
        background: #e9412f;
        color: #212529;
        font-size: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
      }
    }
  }
}

.games_info_box {
  margin: 0 25px;
  background: linear-gradient(-180deg, #4a90e2, #4a90e2);
  padding: 40px;
  margin-top: 150px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  .left {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

    .left_ico {
      width: 160px;
      height: 160px;
      border-radius: 100%;
    }
  }

  .right {
    flex: 1;
    padding-left: 40px;

    .title {
      background: #f5f5f5;
      padding: 10px 20px;
      border-radius: 25px;
      color: #4a90e2;
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 20px;
      text-align: center;
      text-transform: capitalize;
    }

    .center_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;

      .code {
        color: #ff0;
        font-size: 24px;
      }

      .time {
        font-size: 24px;
        font-weight: 500;
      }
    }

    .bottom_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;

      .func_btn {
        width: 45%;
        background: #f5f5f5;
        padding: 10px 20px;
        border-radius: 25px;
        color: #4a90e2;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
        text-transform: capitalize;
      }
    }
  }
}

.van-count-down {
  color: white;
  font-size: 24px;

}

.record_trans_bix {
  margin: 0 25px;

  .title {
    color: #444756;
    font-size: 28px;
    margin: 30px 0 0 0;
  }

  .list {
    .item_box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .txt {
        color: #444756;
        font-size: 24px;
        margin: 30px 0 0 0;
      }

      .txt1 {
        color: #4a90e2;
        font-size: 24px;
        margin: 30px 0 0 0;
        font-weight: 600;
      }
    }
  }
}

.select_box {
  margin: 0 25px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0;

    .left {
      color: #444756;
      font-size: 28px;
      font-weight: 600;
    }

    .right {
      color: #444756;
      font-size: 28px;
      font-weight: 500;

      .span {
        color: #d30e0e;
        font-size: 28px;
        font-weight: 500;
      }
    }
  }

  .select_list {
    display: flex;
    gap: 10px;

    .item_box {
      width: 100%;

      .item_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: white;

        border-radius: 20px;

        .number {
          background: linear-gradient(180deg, #edba2e, #fcde58, #edba2e, rgba(237, 186, 46, .639), rgba(181, 88, 252, 0));
          border-top-right-radius: 20px;
          border-top-left-radius: 20px;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          height: 40px;
          width: 100%;
          color: black;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .sel_ico {
          width: 120px;
          height: 120px;
          border-radius: 100%;
        }

        .level {
          color: #4a90e2;
          display: block;
          font-size: 26px;
          text-align: center;
          margin-top: 20px;
        }

        .minnumber {
          color: #444756;
          display: block;
          font-size: 26px;
          text-align: center;
          margin-top: 20px;
        }
      }

    }
  }
}

.nav-bar .right {
  padding-left: 8px;
  padding-right: 8px;
  color: #fff;
  font-size: 28px;
  border-radius: 10px;
  border: 2px solid #fff;
  line-height: 60px;
}





.linear-gradient {
  width: 100%;
  height: 2px;
}

.record .recent {
  display: flex;
  align-items: center;
  height: 110px;
}

.kuaisan-ball .left {
  justify-content: flex-start;
}

.kuaisan-ball {
  flex: 1;
  display: flex;
  align-items: center;
}

.kuaisan-ball .res-img {
  width: 70px;
  height: 70px;
  margin-right: 30px;
}

.kuaisan-ball .res-des {
  font-weight: 700;
  text-align: center;
  color: #000;
}

.kuaisan-ball .res-des.middle {
  width: 15%;
  font-size: 35px;
}

.van-icon {
  font-size: 40px;
}

.down {
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.up {
  transform: rotate(180deg);
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.wrapper {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.options-bar {
  display: flex;
  align-items: center;
  height: calc(100% - 80px);
}

.options-bar .game {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.options-bar .game .tips {
  display: flex;
  align-items: center;
  height: 100px;
  padding: 0 20px;
}

.options-bar .game .tips .odds {
  flex: 1;
  font-size: 35px;
  font-weight: 500;
  color: #ff253f;
}

.options-bar .game .tips .play-tip {
  display: flex;
  align-items: center;
  height: 100%;
}

::v-deep .van-icon-more-o {
  color: #ff253f;
  font-size: 50px;
}

.options-bar .game .tips .play-tip .span-text {
  margin-left: 10px;
  font-size: 35px;
  font-weight: bolder;
  color: #ff253f;
}

.linear-gradient {
  width: 100%;
  height: 2px;
}

.sumValueTwoSides {
  display: flex;
  padding: 30px 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.rectangle {
  overflow: hidden;
}

.rectangle.large {
  margin: 0 0 30px 20px;
  width: calc(25% - 25px);
  border-radius: 10px;
}

.rectangle .wrapper {
  position: relative;
  // padding: 0 10px;
  background: #fff;
}

.rectangle .wrapper .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}


.rectangle .wrapper .content .name-text.large {
  font-size: 26px;
  color: #ff253f;
  margin: 20px 0 0;
}

.rectangle .wrapper .content .name-text {
  color: #7d7c7c;
  // font-weight: bolder;
}

.rectangle .wrapper .content .odd-text.large {
  font-size: 25px;
  margin-top: 10px;
}

.rectangle .wrapper .content .odd-text {
  text-align: center;
  color: #999;
}

.bottom-bar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  z-index: 2;
}

.bottom-bar .bar .left,
.bottom-bar .bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bottom-bar .bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 #cacaca;
  z-index: 2;
}

.bottom-bar .bar .left,
.bottom-bar .bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bottom-bar .bar .left .item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  font-size: 20px;
}

.bottom-bar .bar .left .item .text {
  font-size: 22px;
  color: #7d7c7c;
}

.jixuanico {
  font-size: 45px;
}

.bottom-bar .bar .left .line {
  width: 2px;
  height: 50px;
  background: #dadada;
}

.bottom-bar .bar .mid {
  margin-left: 10px;
  flex: 1;
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
}

.bottom-bar .bar .mid .text {
  font-size: 30px;
  font-weight: 500;
  color: #000;
}

.bottom-bar .bar .mid .text.num {
  margin: 0 5px;
  color: #ff253f;
}

.bottom-bar .bar .right {
  padding: 0 30px;
  margin: 0 30px;
  color: #fff;
  background: linear-gradient(270deg, #4a90e2, #4a90e2);
  font-size: 40px;
  font-weight: 500;
  height: 70px;
  line-height: 70px;
  border-radius: 50px;
}

.rectangle.active .wrapper {
  background-color: #ff253f !important;
}



.wrapper .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding: 10px 0;
}

.wrapper .item .left {
  width: 40%;
  font-size: 30px;
  text-align: center;
  font-weight: 500;
  color: #000;
}

.font-weight {
  font-weight: 700 !important;
}

.wrapper .item .right {
  flex: 1;
  display: flex;
  font-size: 30px;
  justify-content: center;
  overflow: hidden;
  color: #000;
}

.wrapper .item .kuaisan-ball .left {
  justify-content: flex-start;
}

.wrapper .item .kuaisan-ball {
  margin-left: 20px;
  flex: 1;
  display: flex;
  align-items: center;
}

.wrapper .item .kuaisan-ball .res-img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.wrapper .item .kuaisan-ball .res-des {
  font-weight: 700;
  text-align: center;
  color: #000;
}

.wrapper .item .kuaisan-ball .res-des.middle {
  width: 15%;
  font-size: 35px;
}




.rectangle.active .wrapper {
  background-color: #ff253f !important;
}

.rectangle.active .wrapper .name-text,
.rectangle.active .wrapper .odd-text {
  color: #fff !important;
}

.bottom-bar .wrapper {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  padding: 20px 20px 10px 20px;
  height: 230px;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 0 10px 0 #cacaca;
  transition: transform 0.3s cubic-bezier(0.21, 1.02, 0.55, 1.01);
}

.bottom-bar .wrapper.active {
  transform: translateY(-100%);
}

.bottom-bar .wrapper .item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 65px;
}

.bottom-bar .wrapper .item .label {
  font-size: 30px;
  line-height: 30px;
  color: #000;
}

.bottom-bar .wrapper .item .bet-number {
  flex: 1;
  margin: 0 16px;
  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  color: #ff253f;
  font-size: 30px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
}

.bottom-bar .wrapper .item .amount-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.van-cell {
  font-size: 30px;
  line-height: 50px;
}

.bottom-bar .wrapper .item .part {
  margin-right: 20px;
}

.bottom-bar .wrapper .item .part span {
  font-size: 30px;
  vertical-align: center;
  color: #000;
}

.bottom-bar .wrapper .item .part .number {
  margin: 0 5px;
  color: #ff253f;
  font-weight: 500;
}
</style>
  
  